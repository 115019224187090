import React from 'react';
import { camelizeKeys } from 'humps';
import { shape } from 'prop-types';
import { graphql } from 'gatsby';
import { isEmpty } from 'lodash';

import Layout from '../../components/Layout';
import DynamicZone from '../../components/DynamicZone';

const FightCard = ({ data }) => {
  const pageData = camelizeKeys(data.strapi.fightCard);
  const headerData = data.strapi && data.strapi.header;
  const footerData = data.strapi && data.strapi.footer;
  const signUpData = data.strapi && data.strapi.signUp;

  return (
    <Layout headerData={headerData} footerData={footerData} signUpData={signUpData}>
      {pageData && !isEmpty(pageData.content) && (
        <DynamicZone components={pageData.content} />
      )}
    </Layout>
  );
};

FightCard.propTypes = {
  data: shape({
    strapi: shape(),
  }),
};

FightCard.defaultProps = {
  data: {
    strapi: {},
  },
};

export default FightCard;

export const fightCartQuery = graphql`
  query getFightCard {
    strapi {
      signUp {
        title
        label
        buttonLabel
        backgroundImage {
          url
        }
      }
      footer {
        id
        links {
          id
          label
          path
          openInNewWindow
          offerIdEverflow
          data {
            path
            label
            openInNewWindow
          }
        }
        socialLinksTitle
        logo {
          id
          url
        }
        socialLinks {
          label
          id
          socialLink {
            path
            label
            logo {
              id
              url
            }
            hoverImage {
              id
              url
            }
          }
        }
        linksTitle
      }
      header {
        id
        links {
          id
          isRed
          linkData {
            id
            label
            path
            openInNewWindow
            offerIdEverflow
            data {
              id
              label
              path
              openInNewWindow
            }
          }
        }
        socialLinks {
          id
          label
          socialLink {
            path
            label
            logo {
              url
            }
          }
        }
        logo {
          url
        }
      }
      fightCard {
        id
        content {
          ... on STRAPI_ComponentSectionsFightCardSection {
            id
            date
            dateTimezone
            leftCornerName
            rightCornerName
            newTitle: title
            location
            backgroundText
            buttons {
              id
              label
              path
              openInNewWindow
              offerIdEverflow
              data {
                path
                openInNewWindow
                label
              }
            }
            cards {
              id
              rounds
              title
              isFeatured
              leftFighter {
                id
                firstName
                secondName
                records
                height
                reach
                weight
                age
                location
                image {
                  url
                }
              }
              rightFighter {
                id
                firstName
                secondName
                records
                height
                reach
                weight
                age
                location
                image {
                  url
                }
              }
            }
            __typename
          }
          ... on STRAPI_ComponentSectionsBannerWithOneEvent {
            id
            __typename
            mobileBackground {
              url
            }
            data {
              id
              bannerLinks {
                label
                path
                openInNewWindow
                offerIdEverflow
                data {
                  path
                  label
                  openInNewWindow
                }
              }
              event {
                label
                data {
                  description
                  title
                  slug
                  id
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
